import {Panel} from "../../bananaframework/src/controls/Panel.js";
import {Checkbox} from "../../bananaframework/src/controls/Checkbox.js";
import {InputControl} from "../../bananaframework/src/controls/InputControl.js";
import {Image} from "../../bananaframework/src/controls/Image.js";
import {Button} from "../../bananaframework/src/controls/Button.js";

import {Modal} from "../modal.js";
import {Localizer} from "../../util/localizer.js";
import {Favorites} from "../../util/favorites.js";
import {Router} from "../../util/router.js";
import {UrlManager} from "../../bananaframework/src/util/UrlManager.js";
import {Dropdown} from "../../bananaframework/src/controls/listcontrols/Dropdown.js";

export class DebugMenuModal extends Modal {

    init(urlIdentifier,disableUrlHistory,data) {
        super.init(urlIdentifier,disableUrlHistory);
    }

    createComponents() {
        super.createComponents();

        var dim = this.getPage().getDimensions();

        var p = new Panel();
        p.setStyle("height:" + (dim.height - 50) + "px;justify-content: flex-start;");
        //p.setStyle("justify-content:space-between;");
        p.addCssClass("flexVerticalAlign");

        var title = new Panel();
        title.addCssClass("addFavoriteModalTitle");
        title.addControl(Localizer.getString("Debug Menu"));
        p.addControl(title);


        var resultP = new Panel();
        resultP.addCssClass("suggestionsItemTitle");
        resultP.setStyle("justify-content: space-around");
        resultP.addControl("custom interstitials");
        p.addControl(resultP);

        var triggerInterstitial = new Button();
        triggerInterstitial.addControl("show interstitial (android)");
        triggerInterstitial.addCssClass("button2");
        triggerInterstitial.setStyle("width:80%;");
        p.addControl(triggerInterstitial);

        triggerInterstitial.bind("click", () => {
            var country = null;
            if (localStorage.getItem("debug_interstitial_country")){
                country = localStorage.getItem("debug_interstitial_country");
            }
            Router.showCustomInterstitial(true,country,0);
        });


        var label = new Panel();
        label.addCssClass("");
        label.setStyle("justify-content: space-around");
        label.addControl("");
        p.addControl(label);

        var countrySelect = new Dropdown();
        countrySelect.addCssClass("dropdown");
        countrySelect.preOptionsText = "Force country:";
        countrySelect.postOptionsText = "";
        countrySelect.setStyle("width:80%;");
        p.addControl(countrySelect);
        countrySelect.bind("dataChanged", (e) => {
           console.log("data changed",countrySelect.getData());
           localStorage.setItem("debug_interstitial_country",countrySelect.getData());
        });
        var countries = this.getAdCountries();
        if (localStorage.getItem("debug_interstitial_country") !== null) {
            countrySelect.setData(localStorage.getItem("debug_interstitial_country"));
        }
        countrySelect.setDataSource(countries);

        var behaviorSelect = new Dropdown();
        behaviorSelect.addCssClass("dropdown");
        behaviorSelect.preOptionsText = "";
        behaviorSelect.postOptionsText = "";
        behaviorSelect.setStyle("width:80%;");
        //p.addControl(behaviorSelect);
        behaviorSelect.bind("dataChanged", (e) => {
            console.log("data changed",behaviorSelect.getData());
            localStorage.setItem("debug_interstitial_ratebehavior",behaviorSelect.getData());
        });
        var behaviors = this.getAdBehaviors();
        if (localStorage.getItem("debug_interstitial_ratebehavior") !== null) {
            behaviorSelect.setData(localStorage.getItem("debug_interstitial_ratebehavior"));
        }
        behaviorSelect.setDataSource(behaviors);



        var resultP = new Panel();
        resultP.addCssClass("suggestionsItemTitle");
        resultP.setStyle("justify-content: space-around");
        resultP.addControl("admob interstitials");
        p.addControl(resultP);

        var triggerInterstitial = new Button();
        triggerInterstitial.addControl("show admob interstitial (android)");
        triggerInterstitial.addCssClass("button2");
        triggerInterstitial.setStyle("width:80%;");
        p.addControl(triggerInterstitial);

        triggerInterstitial.bind("click", () => {
            Router.showAdmobInterstitial();
        });

        // var selectedContainer = new Panel();
        // selectedContainer.addCssClass("selectedCountriesContainer");
        // var title = new Panel();
        // title.setStyle("font-size: 12px;font-weight: bold;margin-bottom: 10px;");
        // this.selectedCount = title;
        //
        // selectedContainer.addControl(title);
        // p.addControl(selectedContainer);
        //
        // var searchResults = new Panel();
        // searchResults.addCssClass("addFavoriteModalSuggestList");
        // searchResults.setStyle("height:100%;overflow-y:scroll;width:85%;");
        // p.addControl(searchResults)

        this.addControl(p)
        this.setVisible(false);

        setTimeout(() => {
            this.setVisible(true);
            this.reValidateSize();
        }, 50);
    }

    getAdCountries () {
        const data = [
            {"key":"-1","value":"use device country (default)"},
            {"key":null,"value":null},
            {"key":"NL","value":"Netherlands"},
            {"key":"DE","value":"Germany"},
            {"key":"FR","value":"France"},
            {"key":"GB","value":"United Kingdom"},
            {"key":"US","value":"United States"},
            {"key":"IT","value":"Italy"},
            {"key":"ES","value":"Spain"},
            {"key":"PL","value":"Poland"},
            {"key":"PT","value":"Portugal"},
            {"key":"RO","value":"Romania"},
            {"key":"TR","value":"Turkey"},
            {"key":"RU","value":"Russia"},
            {"key":"VN","value":"Vietnam"},
            {"key":"SM","value":"San Marino"},
        ]
        return data;
    }

    getAdBehaviors () {
        const data = [
            {"key":"-1","value":"use device rate limit (default)"},
            {"key":null,"value":null},
            {"key":"1","value":"Disable rate limit (always show)"}
        ]
        return data;
    }
};


