import {UrlManager} from "../bananaframework/src/util/UrlManager.js";
import {ManiaCache} from "./cache.js";
import {StateManager} from "../data/stateManager.js";
import {PushMessaging} from "./pushmessaging.js";

export const Router = {

    bannerManuallyHidden : false,

    init : function(){
        window.addEventListener('popstate', function(event)
        {
           /// alert("location: " + document.location + ", state: " + JSON.stringify(event.state));
        });

        Router.showBanner();
    },

    showMatchScreen : function(page,data){
        window.lastBannerLoad = null;
        Router.showInterstitial();
        ManiaCache.setMemoryCache("match",data);
        AppInstance.activePage.onPageDetached();
        AppInstance.loadPage("Match",{"mid":data.id,"referalData":Router.getFutureReferalData()},false,function(){});
        setTimeout(function() {
            Router.updateSessionTime();
            Router.showBanner();
        }, 300);
        //
    },

    showHome : function(page,ignoreStateSaveOnUnload){
        window.lastBannerLoad = null;
        var referalData = {"referalData":Router.getFutureReferalData()};

        if (window.firstIsPageLoaded){
            Router.showInterstitial();
        }
        //mark home page to not store state. so we enter a fresh new home page
        //only applicable when we are in home page
        page.pageControl.ignoreStateSaveOnUnload = ignoreStateSaveOnUnload;
        StateManager.flushPageState("Home");

        AppInstance.loadPage("Home",referalData);

        setTimeout(function() {
            Router.updateSessionTime();
        }, 1000);

        if (Router.bannerManuallyHidden) {
            Router.bannerManuallyHidden = false;
            Router.showBanner();
        }
        setTimeout(function() {
            Router.showBanner();
            Router.showBanner();
        },400);
    },

    showAgendaScreen : function(date){
        window.lastBannerLoad = null;
        Router.showInterstitial();
        AppInstance.activePage.onPageDetached();
        AppInstance.loadPage("Agenda",{"date":date,"referalData":Router.getFutureReferalData()},false,function(){});
        setTimeout(function() {
            Router.updateSessionTime();
            Router.showBanner();
        }, 400);
    },

    showCountryLeaguesScreen : function(data){
        window.lastBannerLoad = null;
        Router.showInterstitial();
        AppInstance.activePage.onPageDetached();
        AppInstance.loadPage("CountryLeagues",{"cid":encodeURI(data.country),"referalData":Router.getFutureReferalData()},false,function(){});
        setTimeout(function() {
            Router.updateSessionTime();
            Router.showBanner();
        }, 400);
    },

    showLeagueScreen : function(data,extradata){
        window.lastBannerLoad = null;
        Router.showInterstitial();
        AppInstance.activePage.onPageDetached();
        if (extradata){
            AppInstance.loadPage("League",{"extradata":btoa(encodeURI(JSON.stringify(extradata))),"lid":data.key||data.leagueKey||data.keygs,"referalData":Router.getFutureReferalData()},false,function(){});
        }
        else {
            AppInstance.loadPage("League",{"lid":data.key||data.leagueKey||data.keygs,"referalData":Router.getFutureReferalData()},false,function(){});
        }
        setTimeout(function() {
            Router.updateSessionTime();
            Router.showBanner();
        }, 300);
    },

    showTeamScreen : function(id){
        window.lastBannerLoad = null;
        Router.showInterstitial();
        AppInstance.activePage.onPageDetached();
        AppInstance.loadPage("Team",{"tid":id,"referalData":Router.getFutureReferalData()},false,function(){});
        Router.updateSessionTime();

        setTimeout(function(){
            Router.updateSessionTime();
            Router.showBanner();
        },1000)
    },

    showPlayerScreen : function(id){
        window.lastBannerLoad = null;
        Router.showInterstitial();
        AppInstance.activePage.onPageDetached();
        AppInstance.loadPage("Player",{"pid":id,"referalData":Router.getFutureReferalData()},false,function(){});

        setTimeout(function() {
            Router.updateSessionTime();
            Router.showBanner();
        }, 400);
    },

    showCoachScreen : function(id){
        window.lastBannerLoad = null;
        Router.showInterstitial();
        AppInstance.activePage.onPageDetached();
        AppInstance.loadPage("Coach",{"cid":id,"referalData":Router.getFutureReferalData()},false,function(){});

        setTimeout(function() {
            Router.updateSessionTime();
            Router.showBanner();
        }, 300);
    },

    showPlayerMarketValues : function(selectedPlayerId){
        window.lastBannerLoad = null;
        Router.showInterstitial();
        AppInstance.activePage.onPageDetached();
        var params = {};
        if (selectedPlayerId){
            params.selectedPlayerId = selectedPlayerId;
            AppInstance.loadPage("PlayerValuesRanking",{"spid":selectedPlayerId,"referalData":Router.getFutureReferalData()},false,function(){},params);
        }
        else{
            AppInstance.loadPage("PlayerValuesRanking",{"referalData":Router.getFutureReferalData()},false,function(){},params);
        }
        setTimeout(function() {
            Router.updateSessionTime();
            Router.showBanner();
        }, 200);
    },

    showTopVoters : function(){
        window.lastBannerLoad = null;
        Router.showInterstitial();
        AppInstance.activePage.onPageDetached();
        AppInstance.loadPage("TopVoters",{"referalData":Router.getFutureReferalData()},false,function(){});
        setTimeout(function() {
            Router.updateSessionTime();
            Router.showBanner();
        }, 200);
    },

    showNews : function(id){
        window.lastBannerLoad = null;
        Router.showInterstitial();
        AppInstance.activePage.onPageDetached();
        AppInstance.loadPage("News",{"referalData":Router.getFutureReferalData()},false,function(){});
        setTimeout(function() {
            Router.updateSessionTime();
        }, 1000);

        if (AppInstance.getConfig().appmode == "ios") {
            Router.hideBanner();
        }
    },

    showFifaRanking : function(){
        window.lastBannerLoad = null;
        Router.showInterstitial();
        AppInstance.activePage.onPageDetached();
        AppInstance.loadPage("Fifaranking",{"referalData":Router.getFutureReferalData()},false,function(){});
        setTimeout(function() {
            Router.updateSessionTime();
            Router.showBanner();
        }, 200);
    },

    showForumIndex : function(){
        Router.showInterstitial();
        Router.showBanner();
        AppInstance.activePage.onPageDetached();
        AppInstance.loadPage("ForumIndex",{"referalData":Router.getFutureReferalData()},false,function(){});
        setTimeout(function() {
            Router.updateSessionTime();
            Router.showBanner();
        }, 300);
    },

    showForumChat : function(index,name){
        Router.showInterstitial();
        Router.hideBanner();
        AppInstance.activePage.onPageDetached();
        AppInstance.loadPage("ForumChat",{"index":index,"title":encodeURI(name),"referalData":Router.getFutureReferalData()},false,function(){});
        setTimeout(function() {
            Router.updateSessionTime();
        }, 1000);
    },

    showSettings : function(){
        if (window.JSAndroidBridge){
            window.JSAndroidBridge.showSettings();
        }
        else {
            AppInstance.loadPage("Settings");
        }
    },

    voteMatch : function(matchid,side){
        window.lastBannerLoad = null;
        if (window.JSAndroidBridge){
            window.JSAndroidBridge.voteMatch(matchid,side);
        }
    },

    updateSessionTime : function(){
        if (window.JSAndroidBridge && window.JSAndroidBridge.updateSessionLength){
            window.JSAndroidBridge.updateSessionLength();
        }
    },

    loadInterstitial : function(){
        if (window.JSAndroidBridge){
            window.JSAndroidBridge.loadInterstitialAd();
        }
    },

    showInterstitial : function(){
        if (window.JSAndroidBridge){
            window.JSAndroidBridge.showInterstitialAd();
        }
    },

    showAdmobInterstitial : function(){
        if (window.JSAndroidBridge){
            window.JSAndroidBridge.showAdmobInterstitial();
        }
    },

    showCustomInterstitial : function(forceShow,country,campaignId){
        if (window.JSAndroidBridge){
            window.JSAndroidBridge.showCustomInterstitialAd(forceShow,country,campaignId);
        }
    },

    showBanner : function(){

        if (window.lastBannerLoad && (new Date().getTime() - window.lastBannerLoad) < 5000){
            console.warn("Banner already loaded recently");
            return;
        }
        window.lastBannerLoad = new Date().getTime();

        if (window.JSAndroidBridge){
            window.JSAndroidBridge.showBannerAd();
        }
        else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iosBridge){
            window.webkit.messageHandlers.iosBridge.postMessage({"method":"loadBanner"});
        }
    },

    hideBanner : function(){
        if (window.JSAndroidBridge){
            window.JSAndroidBridge.hideBannerAd();
            Router.bannerManuallyHidden = true;
        }
        else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iosBridge){
            window.webkit.messageHandlers.iosBridge.postMessage({"method":"hideBanner"});
        }
    },

    openUrl : function(url,newPage){
        if (window.JSAndroidBridge && window.JSAndroidBridge.openWebPage){
            window.JSAndroidBridge.openWebPage(url,newPage ? true : false);
        }
        else{
            window.open(url, '_blank');
        }
    },

    allowBackButtonQuitApp : function(){
        if (window.JSAndroidBridge){
            console.log("allow back button quit");
            window.JSAndroidBridge.enableBackImmediateQuite();
        }
    },

    disallowBackButtonQuitApp : function(){
        if (window.JSAndroidBridge){
            console.log("disallow back button quit");
            window.JSAndroidBridge.disableBackImmediateQuite();
        }
    },

    isNavigatedFromPush : function(){
        return UrlManager.getModule("fromPush") == 1;
    },

    getFutureReferalData : function(){
        var referalData = {
            "origin_section" : UrlManager.getModule("section"),
        }
        return btoa(JSON.stringify(referalData));
    },

    getCurrentReferalData : function(){
        var referalData = UrlManager.getModule("referalData");
        if (!referalData) return null;
        referalData = decodeURIComponent(referalData);
        //referalData = btoa(referalData);
        try{
            return JSON.parse(atob(referalData));
        }
        catch (e){
            console.error("Invalid referal data",referalData);
            return null;
        }
    },

    updateCurrentReferalData : function(){
        var referalData = Router.getFutureReferalData();
        //history.replaceState(referalData, undefined, "#referalData")
        UrlManager.registerModule("referalData");
        UrlManager.setModule("referalData",encodeURIComponent(referalData),true,true)
    },

    navigationIsFromOutside : function(){
        if (!Router.getCurrentReferalData()) return false;
        return Router.getCurrentReferalData().origin_section != UrlManager.getModule("section");
    }
};

Router.init();